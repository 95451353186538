import React from 'react';
import { useUuiContext } from '@epam/uui-core';
import { Api } from '../services';
import { userPickerQuery } from '../queries';
import { LookupPicker } from './LookupPicker';

type UserPickerProps = {
  allowNulls?: false,
  value: number,
  onValueChange: (value: number) => void,
  placeholder?: string,
} | {
  allowNulls: true,
  value: number | undefined,
  onValueChange: (value: number | undefined) => void,
  placeholder?: string,
}

export const UserPicker = (props: UserPickerProps) => {
    const svc = useUuiContext<Api>();

    return props.allowNulls 
      ? <LookupPicker
          { ...props }
          allowNulls= { props.allowNulls }
          placeholder={props.placeholder}
          query={userPickerQuery}
          queryProp='users'
        />
      : <LookupPicker
          { ...props }
          allowNulls= { props.allowNulls }
          defaultValue={svc.uuiApp.defaultCategoryId}
          placeholder={props.placeholder}
          query={userPickerQuery}
          queryProp='users'
        />;
}
