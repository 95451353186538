import React, { useCallback } from 'react';
import { NumericInput, TextInput, FlexCell, FlexRow, Text, LabeledInput, Button, Dropdown, DropdownContainer, RangeDatePicker } from '@epam/promo';
import { DataColumnProps, useTableState  } from '@epam/uui-core';
import { AdaptiveItemProps, AdaptivePanel } from '@epam/uui-components';
import { CategoryFilter, GraphQLCategory } from 'common';

import { MasterDetail } from '../components/MasterDetail';
import { categoriesQuery } from '../queries';
import { amountColumn } from '../components/amountColumn';
import { Range } from 'common/schema/Range';

const columns: DataColumnProps<GraphQLCategory>[] = [
  {
    key: 'name',
    caption: 'NAME',
    render: category => <Text color='gray80'>{ category.icon } {' '} { category.name }</Text>,
    isSortable: true,
    grow: 1,
    width: 224,
  },
  amountColumn<GraphQLCategory>({ key: 'sum', caption: 'SUM', amountGetter: category => category.sum }),
  amountColumn<GraphQLCategory>({ key: 'all_sum', caption: 'SUM (ALL)', amountGetter: category => category.sumAll }),
];

const defaultDateRange = {
  from: null,
  to: null,
}

function getExtraFromFilter(filter: CategoryFilter): { filter: CategoryFilter, dateRange?: Range<string> } {
  const { dateRange, ...rest } = filter;

  return {
    filter: rest,
    dateRange,
  };
}

const getId = (item: GraphQLCategory) => item.id;
const getParentId = (item: GraphQLCategory) => item.parentId;
const getChildCount= (item: GraphQLCategory) => item.childCount;

export const CategoriesPage = () => {
  const tableState = useTableState<CategoryFilter>({ initialPresets: [] });

  const onDateRangeValueChange = useCallback((value: Range<string>) => {
    tableState.setFilter({
      dateRange: value,
    });
  }, [tableState]);

  const renderItem = (item: AdaptiveItemProps) => {
      return (
        <FlexCell width={200}>
          <RangeDatePicker value={tableState.tableState.filter?.dateRange ?? defaultDateRange} onValueChange={onDateRangeValueChange} format='DD/MM/YY' />
        </FlexCell>
      )
  };

  const renderHiddenItem = (item: AdaptiveItemProps) => {
      return (
        <FlexRow padding='6' vPadding='12'>
          {renderItem(item)}
        </FlexRow>
      )
  };

  const items: AdaptiveItemProps<{data?: {caption: string}}>[] = [
    { id: '1', render: renderItem, priority: 1 },
    { id: '5', render: (item, hiddenItems) => <Dropdown
          renderTarget={ (props) => <Button caption='Hidden Filters...' { ...props } /> }
          renderBody={ () => <DropdownContainer>{ hiddenItems?.map(renderHiddenItem) }</DropdownContainer> }
      />,
      priority: 10, collapsedContainer: true,
    },
  ];  

  return <MasterDetail<GraphQLCategory, CategoryFilter>
    masterQuery={ categoriesQuery }
    detailQuery = { categoriesQuery }
    pathname='categories'
    resultQueryProp='categories'
    columns={ columns }
    Detail={ CategoryDetails }
    tableState={ tableState }
    getExtraFromFilter={ getExtraFromFilter }
    getId={getId}
    getParentId={getParentId}
    getChildCount={getChildCount}
  >
    <AdaptivePanel items={items} />
  </MasterDetail>;
};

interface CategoryDetailsProps {
  item: GraphQLCategory;
}

const CategoryDetails = ({ item }: CategoryDetailsProps) => {
  return (
    <>
      <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
            <LabeledInput label='ID'>
                  <NumericInput
                      value={ item.id ?? null }
                      onValueChange={ () => {} }
                      isReadonly
                  />
            </LabeledInput>
          </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
          <FlexCell grow={1}>
            <LabeledInput label='Name'>
                  <TextInput
                      value={ item.name }
                      onValueChange={ () => {} }
                      isReadonly
                  />
            </LabeledInput>
          </FlexCell>
      </FlexRow>
      </>
  );
}