import { Text } from '@epam/promo';
import React from 'react';
import { format, parseISO } from 'date-fns';

interface DateTimeProps {
  value: string;
}

const FORMAT_DATE = 'dd/MM/yyyy HH:mm';

export const DateTime = (props: DateTimeProps) => {
  return <Text color='gray80'>{ format(parseISO(props.value), FORMAT_DATE) }</Text>
}