import React, { ComponentType, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { FlexCell, FlexRow, DataTable, Panel, Text, ScrollBars, Spinner, FlexSpacer, IconButton } from '@epam/promo';
import { cx, DataColumnProps, DataRowProps, ITableState, LazyDataSourceApiRequest, LazyDataSourceApiRequestContext, useLazyDataSource, useUuiContext, UuiError } from '@epam/uui-core';
import { ReactComponent as CrossIcon } from "@epam/assets/icons/common/navigation-close-24.svg";
import { Api, AppContext } from '../services';
import css from './MasterDetail.module.scss';
import { useSelectedIdQueryParam } from '../hooks/useUrlState';

export interface MasterDetailProps<TItem, TFilter> {
  pathname: string;
  masterQuery: string;
  detailQuery: string;
  resultQueryProp: string;
  columns: DataColumnProps<TItem>[];
  Detail: ComponentType<ItemProps<TItem>>;
  search?: string;
  tableState: ITableState<TFilter, any>;
  getExtraFromFilter?(filter: TFilter): { filter: TFilter } & Record<string, any>;
  getId?: (item: TItem) => number;
  getParentId?: (item: TItem) => number | undefined;
  getChildCount?: (item: TItem) => number;
  showCheckboxes?: boolean;
  patch?: TItem[];
  setPatch?: (value: TItem[] | undefined) => void;
};

export const MasterDetail = <TItem, TFilter = {}>(props: PropsWithChildren<MasterDetailProps<TItem, TFilter>>) => {
  const svc = useUuiContext<Api, AppContext>();
  const tableState = props.tableState;
  const [selectedId, setSelectedId] = useSelectedIdQueryParam();

  const closeInfoPanel = useCallback(() => setSelectedId(undefined), [setSelectedId]);
  const clickHandler = useCallback((rowProps: DataRowProps<TItem, number>) => setSelectedId(rowProps.id), [setSelectedId]);

  const { getExtraFromFilter, getId, getParentId, getChildCount, masterQuery, resultQueryProp, search } = props;

  const api = useCallback(async (
    request: LazyDataSourceApiRequest<TItem, number, TFilter>,
    context?: LazyDataSourceApiRequestContext<TItem, number>,
  ) => {
    const { filter, ...rest } = request;
    const extra = getExtraFromFilter && filter
      ? getExtraFromFilter(filter)
      : { filter };

    if (getParentId && context) {
      (extra.filter as any).parentId = context.parentId;
    }

    const response = await svc.api.query({
      query: masterQuery,
      variables: {
        ...rest,
        ...extra,
        search,
      }
    });

    return {
      items: response.data[resultQueryProp],
    };
  }, [svc, getExtraFromFilter, getParentId, masterQuery, search, resultQueryProp]);

  const dataSource = useLazyDataSource<TItem, number, TFilter>({
    api,
    getId,
    getParentId,
    getChildCount,
  }, [api, getId, getParentId, getChildCount]);

  if (props.patch?.length) {
    for (const item of props.patch) {
      dataSource.setItem(item);
    }
  }

  props.setPatch?.(undefined);

  const view = dataSource.useView(tableState.tableState, tableState.setTableState, {
    isFoldedByDefault: () => true,
    getRowOptions: () => ({
        onClick: clickHandler,
        ...(props.showCheckboxes && { checkbox: { isVisible: true } }),
    }),
  });

  return (
      <div className={ cx(css.container, css.table) }>
        {props.children}
        <DataTable
            { ...view.getListProps() }
            getRows={ view.getVisibleRows }
            value={ tableState.tableState }
            onValueChange={ tableState.setTableState }
            columns={ props.columns }
            headerTextCase='upper'

        />
          {selectedId && <Detail<TItem>
            Item={ props.Detail }
            query={ props.detailQuery }
            resultQueryProp={ props.resultQueryProp }
            id={ selectedId }
            onClose={ closeInfoPanel }
            setPatch={props.setPatch}
          />}
      </div>
  );
}

interface DetailProps<TItem> {
  id: number;
  onClose(): void;
  query: string;
  resultQueryProp: string;
  Item: ComponentType<ItemProps<TItem>>;
  setPatch?: (value: TItem[] | undefined) => void;
}

type ItemProps<TItem> = {
  item: TItem;
  setPatch?: (value: TItem[] | undefined) => void;
}

const Detail = <TItem,>({ id, query, onClose, resultQueryProp, Item, setPatch }: DetailProps<TItem>) => {
  const [item, setItem] = useState<TItem | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNotFound, setNotFound] = useState<boolean>(false);

  if (isNotFound || isNaN(id)) {
    throw new UuiError({
      status: 404,
    });
  }

  const svc = useUuiContext<Api, AppContext>();

  useEffect(() => {
    setIsLoading(true);
    svc.api.query({
      query,
      variables: {
        filter: {
          id,
        },
      }
    }).then(result => {
      if (result.data && result.data[resultQueryProp] && result.data[resultQueryProp][0]) {
        setItem(result.data[resultQueryProp][0]);
      } else {
        setItem(null);
        setNotFound(true);
      }
      setIsLoading(false);
    });
  }, [id, svc, query, resultQueryProp]);

  return (
    <div className={ cx(css.sidebar, 'show') }>
      { !isLoading && item &&
      <Panel shadow cx={ cx(css.container, css.details) } background='white'>
        <FlexRow borderBottom padding="24">
          <Text size="48">Detailed Information</Text>
          <FlexSpacer />
          <FlexCell shrink={ 0 } width="auto"><IconButton icon={ CrossIcon } onClick={ onClose }/></FlexCell>
        </FlexRow>      { isLoading && <Spinner /> }
        <ScrollBars hasTopShadow hasBottomShadow >
          <Item item={ item } setPatch={setPatch}/>
       </ScrollBars>
      </Panel> }
    </div>
  );
}