import React from 'react';
import * as queryString from 'query-string';

import { useLocation } from 'react-router-dom';
import { useUuiContext } from '@epam/uui-core';

export const useStringQueryParam = (name: string, defaultValue: string) => useQueryParam<string>(name, defaultValue, value => value, value => value);
export const useOptionalStringQueryParam = (name: string) => useQueryParam<string | undefined>(name, undefined, value => value, value => value ?? '');
export const useTabQueryParam = (defaultTab: string) => useQueryParam<string>('tab', defaultTab, value => value, value => value);
export const useOptionalNumberQueryParam = (name: string) => useQueryParam<number | undefined>(name, undefined, value => value ? +value : undefined, value => value ? value.toString() : '');
export const useSelectedIdQueryParam = () => useOptionalNumberQueryParam('selectedId');
export const useSearchQueryParam = () => useOptionalStringQueryParam('search');

function useQueryParam<T>(name: string, defaultValue: T, parse: (str: string) => T, stringify: (value: T) => string): [T, (value: T) => void] {
    const { search, pathname } = useLocation();
    const svc = useUuiContext();

    const parsedParam = React.useMemo(() => {
        const parsedSearch = queryString.parse(search);
        return parsedSearch[name]
            ? parse(parsedSearch[name])
            : defaultValue;
    }, [search, name, defaultValue, parse]);

    const setValue = React.useCallback((newValue: T) => {
        const parsedSearch = queryString.parse(search);
        const newParsedSearch = {
            ...parsedSearch,
            [name]: stringify(newValue),
        }

        if (newValue === undefined || newValue === null) {
            delete newParsedSearch[name];
        }

        svc.uuiRouter.transfer({
            pathname,
            search: queryString.stringify(newParsedSearch),
        });
    }, [search, pathname, svc, name, stringify]);

    return [parsedParam, setValue];
}
