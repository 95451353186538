import React from 'react';
import { DataColumnProps } from '@epam/uui-core';
import { DataTableCell, Text } from '@epam/promo';

export const iconColumn = <T extends unknown>(iconGetter: ((item: T) => string | undefined), extend: boolean = false): DataColumnProps<T> => ({
  key: 'icon',
  caption: null,
  render: item => <Text color='gray60'>{ iconGetter(item) }</Text>,
  isAlwaysVisible: true,
  width: extend ? 90 : 45,
  renderCell: (props) => <DataTableCell
      // { ...props.rowLens.prop('icon').toProps() }
      { ...props }
      columnsGap='12'
      onValueChange={undefined}
    />,
});