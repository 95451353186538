import { ApiCallOptions, CommonContexts as UuiCommonContext} from '@epam/uui-core';
import { ApolloClient, FetchResult } from '@apollo/client';
import { NewPurchase, ProcessSlip } from './models';
import { deletePurchaseMutation, newPurchaseMutation, processSlipMutation, updateCommoditiesCategoryMutation, updateCommodityMutation, updatePurchaseItemMutation, updatePurchaseMutation, updateSellerMutation } from './queries';
import { GraphQLCommodity, GraphQLPurchase, GraphQLPurchaseItem, GraphQLSeller } from 'common';

export type Api = ReturnType<typeof getApi>

type User = {
    id: number,
    name: string,
    telegramName: string,
    avatarUrl: string,
    roles: string[],
    isAdmin: boolean,
};

export type AppContext = {
    isAuthenticated: false,
    isProduction: false,
    user: undefined,
} | {
    isAuthenticated: true,
    isProduction: boolean,
    defaultCategoryId: number,
    user: User,
};

export interface CommonContexts extends UuiCommonContext<Api, AppContext>  {
  apollo: ApolloClient<any>;
}

export const svc: CommonContexts = {} as any;

export type UserInfo = {
  username?: string,
}

const makeMeUsersQuery = `query {
  appContext {
    makeMeUsers {
      id
      name
    }
  }
}`;

export function getApi(processRequest: (request: string, requestMethod: string, data?: any, options?: ApiCallOptions) => any) {
  return {
      query<T = any>(request : { query: string, variables: any }) : Promise<FetchResult<T>> {
        return processRequest('/graphql', 'POST', request, { fetchOptions: { credentials: 'include' } });
      },
      appContext() {
        return processRequest('/users/me', 'GET', undefined, { fetchOptions: { credentials: 'include' } });
      },
      login(user: UserInfo) {
        return processRequest('/users/login', 'POST', user, { fetchOptions: { credentials: 'include' }, errorHandling: 'manual' });
      },
      logout() {
        return processRequest('/users/logout', 'GET', undefined, { fetchOptions: { credentials: 'include' } });
      },
      async updatePurchaseItem(purchaseItem: Partial<GraphQLPurchaseItem>): Promise<GraphQLPurchaseItem | string> {
        const result = await processRequest('/graphql', 'POST', { query: updatePurchaseItemMutation, variables: { item: purchaseItem } }, { fetchOptions: { credentials: 'include' } });
        if (result.errors) {
          return result.errors.toString();
        }
        
        return result.data.purchaseItemUpdate;
      },
      async updateCommodity(commodity: Partial<GraphQLCommodity>): Promise<GraphQLCommodity | string> {
        const result = await processRequest('/graphql', 'POST', { query: updateCommodityMutation, variables: { item: commodity } }, { fetchOptions: { credentials: 'include' } });
        if (result.errors) {
          return result.errors.toString();
        }
        
        return result.data.commodityUpdate;
      },
      async updateSeller(seller: Partial<GraphQLSeller>): Promise<GraphQLSeller | string> {
        const result = await processRequest('/graphql', 'POST', { query: updateSellerMutation, variables: { item: seller } }, { fetchOptions: { credentials: 'include' } });
        if (result.errors) {
          return result.errors.toString();
        }
        
        return result.data.commodityUpdate;
      },
      async updatePurchase(purchase: Partial<GraphQLPurchase>): Promise<GraphQLPurchase | string> {
        const result = await processRequest('/graphql', 'POST', { query: updatePurchaseMutation, variables: { item: purchase } }, { fetchOptions: { credentials: 'include' } });
        if (result.errors) {
          return result.errors.toString();
        }
        
        return result.data.purchaseUpdate;
      },
      async newPurchase(newPurchase: NewPurchase) {
        const result = await processRequest('/graphql', 'POST', { query: newPurchaseMutation, variables: { item: newPurchase } }, { fetchOptions: { credentials: 'include' } });
        return result;
      },
      async deletePurchase(id: number) {
        const result = await processRequest('/graphql', 'POST', { query: deletePurchaseMutation, variables: { id } }, { fetchOptions: { credentials: 'include' } });
        return result;
      },
      async processSlip(item: ProcessSlip) {
        const result = await processRequest('/graphql', 'POST', { query: processSlipMutation, variables: { item } }, { fetchOptions: { credentials: 'include' } });
        return result;
      },
      async makeMeApi(r: any) {
        const makeMe = await processRequest('/graphql', 'POST', {
          query: makeMeUsersQuery,
          variables: {
            search: r.search,
            first: r.range && r.range.count,
            after: r.range && r.range.from ? `${r.range.from - 1}` : null,
            filter: r.ids && { id: { in: r.ids.map((id: any) => +id) } },
          },
        });
       
        return {
          items: makeMe.data.appContext.makeMeUsers,
        };
      },
      async updateCommoditiesCategory(commodityIds: number[], categoryId: number): Promise<string | GraphQLCommodity[]> {
        const result = await processRequest('/graphql', 'POST', { query: updateCommoditiesCategoryMutation, variables: { commodityIds, categoryId } }, { fetchOptions: { credentials: 'include' } });
        if (result.errors) {
          return result.errors.toString();
        }

        return result.data.commoditiesCategoryUpdate;
      },
  };
}
