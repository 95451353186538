import React, { useCallback, useState } from 'react';
import { NumericInput, FlexCell, FlexRow, Text, LabeledInput, Button, FlexSpacer, Blocker, SuccessNotification, ErrorNotification } from '@epam/promo';
import { DataColumnProps, useTableState, useUuiContext,  } from '@epam/uui-core';
import { MasterDetail } from '../components/MasterDetail';
import { SlipKey } from '../models';
import { orphansQuery } from '../queries';
import { amountColumn } from '../components/amountColumn';
import { dateTimeColumn } from '../components/dateTimeColumn';
import { DateTime } from '../components/DateTime';
import { Api } from '../services';

const columns: DataColumnProps<SlipKey>[] = [{
    key: 'id',
    caption: 'Id',
    render: slipKey => <Text color='gray80'>{ slipKey.id }</Text>,
    grow: 1,
    width: 224,
  }, 
  dateTimeColumn<SlipKey>({ key: 'dateTime', caption: 'Date', dateTimeGetter: slipKey => slipKey.dateTime }),
  amountColumn<SlipKey>({ key: 'sum', caption: 'SUM', amountGetter: slipKey => 0.01* slipKey.totalSum }),
  {
    key: 'fiscalDriveNumber',
    caption: 'ФН',
    render: slipKey => <Text color='gray80'>{ slipKey.fiscalDriveNumber }</Text>,
    grow: 1,
    width: 224,
  },
  {
    key: 'fiscalDocumentSign',
    caption: 'ФД',
    render: slipKey => <Text color='gray80'>{ slipKey.fiscalDocumentNumber }</Text>,
    grow: 1,
    width: 224,
  },
  {
    key: 'fiscalSign',
    caption: 'ФП',
    render: slipKey => <Text color='gray80'>{ slipKey.fiscalSign }</Text>,
    grow: 1,
    width: 224,
  },
  {
    key: 'seller',
    caption: 'Seller',
    render: slipKey => <Text color='gray80'>{ slipKey.seller?.name }</Text>,
    grow: 1,
    width: 224,
  },
];

export const OrphansPage = () => {
  const tableState = useTableState<any>({ initialPresets: [] });

  return <MasterDetail<SlipKey>
    masterQuery={ orphansQuery }
    detailQuery = { orphansQuery }
    pathname='orphans'
    resultQueryProp='orphans'
    columns={ columns }
    Detail={ OrphanDetails }
    tableState={ tableState }
  >
  </MasterDetail>;
};

interface OrphanDetailsProps {
  item: SlipKey;
}

const OrphanDetails = ({ item }: OrphanDetailsProps) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const svc = useUuiContext<Api>();

  const save = useCallback(() => {
    setIsProcessing(true);

    svc.api.processSlip({
      dateTime: item.dateTime,
      fiscalDocumentNumber: item.fiscalDocumentNumber,
      fiscalDriveNumber: item.fiscalDriveNumber,
      fiscalSign: item.fiscalSign,
      totalSum: item.totalSum,
    })
      .then(async response => {
        setIsProcessing(false);
        if (response?.errors) {
          throw new Error(response.errors.map((i: any) => i.message).join(', '));
        }

        await svc.uuiNotifications.show(props => (
          <SuccessNotification { ...props }>
            <Text>{ response?.data?.processSlip }</Text>
          </SuccessNotification>
        ), {
          duration: 5,
        });
      })
      .catch(async response => {
        console.error('ERROR', response);
        setIsProcessing(false);
        await svc.uuiNotifications.show(props => (
          <ErrorNotification { ...props }>
            <Text>Something went wrong: { response?.toString() }</Text>
          </ErrorNotification>
        ), {
          duration: 5,
        });
      });
  }, [item, svc, setIsProcessing]);

  return (
    <>
      <Blocker isEnabled={isProcessing} />

      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='ID'>
            <NumericInput
              value={ item.id ?? null }
              onValueChange={ () => {} }
              isReadonly
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='Date/Time'>
            <DateTime
              value={ item.dateTime }
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexCell grow={1}>
          <LabeledInput label='Sum'>
            <NumericInput
              value={ 0.01 * item.totalSum }
              onValueChange={ () => {} }
              isReadonly
              formatOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
            />
          </LabeledInput>
        </FlexCell>
      </FlexRow>
      <FlexRow padding='24' vPadding='12'>
        <FlexSpacer />
        <Button color='red' caption='Reload' onClick={ save } />
        <FlexSpacer />
      </FlexRow>
     </>
  );
}