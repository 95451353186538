import React from 'react';
import { PickerInput } from '@epam/promo';
import { LazyDataSourceApiRequest, useLazyDataSource, useUuiContext } from '@epam/uui-core';
import { Api, AppContext } from '../services';

type LookupPickerProps = {
  allowNulls?: false,
  value: number,
  onValueChange: (value: number) => void,
  placeholder?: string,
  query: string,
  queryProp: string,
  defaultValue: number,
  isReadonly?: boolean,
  searchPosition?: 'input' | 'body' | 'none',
} | {
  allowNulls: true,
  value: number | undefined,
  onValueChange: (value: number | undefined) => void,
  placeholder?: string,
  query: string,
  queryProp: string,
  isReadonly?: boolean,
  searchPosition?: 'input' | 'body' | 'none',
}

export const LookupPicker = <T extends unknown>(props: LookupPickerProps) => {
    const svc = useUuiContext<Api, AppContext>();
    const dataSource = useLazyDataSource<T, number | undefined, unknown>({
      api: async (request: LazyDataSourceApiRequest<T, number | undefined, unknown>) => {
        const response = await svc.api.query({
          query: props.query,
          variables: !!request.ids ? { filter : request } : request,
        });
  
        return {
          items: response.data[props.queryProp],
        };
      },
      selectAll: false
    }, [svc.api, props.queryProp, props.query]);

    return (
      <PickerInput<T, number | undefined>
        dataSource={ dataSource }
        value={props.value}
        onValueChange={props.onValueChange}
        entityName='name'
        selectionMode='single'
        valueType='id'
        emptyValue={ props.allowNulls ? undefined : props.defaultValue as any }
        placeholder={ props.placeholder }
        isDisabled={props.isReadonly}
        searchPosition={props.searchPosition}
      />
    );
}